import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import ym from "react-yandex-metrika"
import { catch_error } from "../util/errors"
import axios from "axios"

export const RegistrationINNPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [load2, setLoad2] = useState(true)

	const [step, setStep] = useState(0)
	const [pk, setPk] = useState(0)

	const [popupType, setPopupType] = useState("")
	const [popupError, setPopupError] = useState(true)
	// TODO: model
	const [form, setForm] = useState<any>({
		name: "",
		email: "",
		phone: "",
		pass: "",
		cPass: "",
		userType: "bloger",
		regLink: "",
		tglogin: "",
		referral_code: null,
	})

	const [formLegal, setFormLegal] = useState({
		LegalFIO: "",
		LegalINN: "",
		LagalConsultation: false,
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.name === "referral_code") {
			setForm((prev) => ({ ...prev, referral_code: event.target.value ? event.target.value.toUpperCase() : null }))
			return
		}

		setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }))
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
		if (!load) return

		if (!["bloger", "seller"].includes(form.userType)) {
			alert(`Ошибка в типе аккаунта: ${form.userType}`)
			return
		}

		const [error, response] = await catch_error(
			auth.user.register_v2(
				form.name,
				form.email.toLowerCase(),
				form.phone,
				form.pass,
				form.cPass,
				form.userType,
				form.regLink,
				form.tglogin,
				form.referral_code,
				//form.LegalFIO,
				//form.LegalINN,
				//form.LagalConsultation
				auth.analytics,
			),
		)

		if (error) {
			console.error(error)

			if (axios.isAxiosError(error)) return alert(`Ошибка: ${error.response?.data?.msg ?? error.response.data}`)

			alert("Ошибка")
			return
		}

		if (response.error) {
			console.error(error, response)

			// TODO: Make Pydantic function for handling errors
			if (response.ctx) {
				// Old: {error: true, msg: "..."}
				// Pydantic: {"detail":[{"type":"string_too_short","loc":["body","item","email"],"msg":"String should have at least 6 characters","ctx":{"min_length":6}}]}
				const old_msg = response.ctx?.error ? response.ctx.msg : null,
					pydantic = response.ctx?.detail?.[0],
					msg = pydantic?.msg,
					loc = pydantic?.loc?.slice?.(2) ?? [],
					ctx = pydantic?.ctx

				console.error(old_msg, msg, loc, ctx)
				if (old_msg) alert(`Ошибка регистрации: ${old_msg}`)
				else alert(`Ошибка регистрации: ${msg} в поле ${loc.join(".")}`)
				return
			}

			alert(`Ошибка регистрации: ${response?.msg ?? JSON.stringify(response)}`)
			return
		}

		setLoad(false)
		ym("reachGoal", "registration_successfully")

		if (form.userType === "bloger") {
			if (!response.pk) {
				alert("Ошибка регистрации, данные по пользователю не вернулись")
				setLoad(true)
				return
			}

			setPk(response.pk)
			setStep(1)
			//setPopupType(response.msg)
			//setPopupError(true)

			const url = new URL(window.location.href)
			url.searchParams.set("uid", response.pk)
			window.history.pushState(null, "", url.toString())
		} else if (form.userType === "seller") {
			setPopupType(response.msg)
			setPopupError(response.error)
		} else {
			setLoad(true)
			throw new Error("Ошибка создания аккаунта")
		}
	}

	const changeHandlerLegal = (event: ChangeEvent<HTMLInputElement>) => {
		setFormLegal({ ...formLegal, [event.target.name]: event.target.value })
	}

	const handleSubmitLegal = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!load2) return

		const [error] = await catch_error(
			auth.user.setLegal(formLegal.LegalFIO, formLegal.LegalINN, formLegal.LagalConsultation, pk),
		)

		if (error) {
			console.error(error)

			if (axios.isAxiosError(error)) return alert(`Ошибка: ${error.response.data?.msg ?? error.response.data}`)

			alert("Ошибка")
			return
		}

		setLoad2(false)
		ym("reachGoal", "forma2")
		setPopupType("Аккаунт успешно создан. На указанный номер телефона отправлено смс для активации аккаунта.")
		setPopupError(false)
	}

	useEffect(() => {
		const validate_integer = (str: string): boolean => /^[0-9]+$/.test(str)

		const url = new URL(window.location.href),
			referral_code = url.searchParams.get("referral_code"),
			uid = url.searchParams.get("uid")

		if (uid && validate_integer(uid)) {
			setStep(1)
			setPk(Number(uid))
		}

		if (!referral_code) return

		setForm((prev) => ({ ...prev, referral_code: referral_code.toUpperCase() }))
	}, [])

	return (
		<>
			<section className="form-item registration-form">
				<div className="content">
					<div className="grid">
						<div className="form-item__wrapper row">
							<div className="col-md-6">
								<div className="grid">
									<div>
										<h1>Создать аккаунт</h1>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{step === 0 ? (
									<form onSubmit={handleSubmit}>
										<label>
											<input
												className="registration-name"
												type="text"
												placeholder="Имя"
												required
												name="name"
												autoComplete="name"
												value={form.name}
												onInput={changeHandler}
											/>
										</label>
										<label>
											<input
												className="registration-email"
												type="email"
												style={{ textTransform: "lowercase" }}
												placeholder="E-mail"
												required
												name="email"
												autoComplete="username"
												value={form.email}
												onInput={changeHandler}
											/>
										</label>
										<label>
											<input
												className="registration-phone"
												type="text"
												minLength={10}
												maxLength={20}
												placeholder="Номер телефона"
												required
												name="phone"
												autoComplete="tel"
												value={form.phone}
												onInput={changeHandler}
											/>
										</label>

										<label>
											<input
												className="registration-telegram"
												type="text"
												placeholder="Ник в telegram"
												required
												name="tglogin"
												autoComplete="telegram"
												value={form.tglogin}
												onInput={changeHandler}
											/>
										</label>

										<label>
											<span
												style={{
													textAlign: "left",
													fontSize: "12px",
													lineHeight: "1.25",
													marginTop: "-5px",
												}}
											>
												Ваш пароль должен содержать не менее восьми (8) символов в том числе: по
												крайней мере одну (1) букву в верхнем регистре
											</span>
											<input
												className="registration-password"
												type="password"
												placeholder="Пароль"
												required
												name="pass"
												autoComplete="new-password"
												value={form.pass}
												onInput={changeHandler}
											/>
										</label>
										<label>
											<input
												className="registration-password-confirm"
												type="password"
												placeholder="Подтвердите пароль"
												required
												name="cPass"
												autoComplete="new-password"
												value={form.cPass}
												onInput={changeHandler}
											/>
										</label>
										<label>
											<select
												className="registration-type"
												defaultValue="bloger"
												onChange={(e) => {
													setForm({ ...form, userType: e.target.value })
												}}
											>
												<option value="seller">Селлер</option>
												<option value="bloger">Блогер</option>
											</select>
										</label>
										{form.userType === "bloger" && (
											<>
												<label>
													<input
														type="text"
														placeholder="Ссылка на соцсеть"
														required
														name="regLink"
														value={form.regLink}
														onInput={changeHandler}
													/>
												</label>
												<label>
													<input
														type="text"
														// placeholder="Реферальный код (опционально)"
														placeholder="Промокод (опционально)"
														name="referral_code"
														value={form.referral_code || ""}
														onInput={changeHandler}
														// style={{ textTransform: "uppercase" }}
													/>
												</label>
											</>
										)}

										<button
											className="registration-submit"
											onClick={(e) => {
												handleSubmit(e)
											}}
										>
											Зарегистрироваться
										</button>
										<p className="form__new-account reg-text">
											*Отправляя данные, вы соглашаетесь с{" "}
											<Link to={"/privacy/"} target="_blank">
												политикой конфиденциальности
											</Link>
										</p>
									</form>
								) : (
									<form onSubmit={handleSubmitLegal}>
										{/* <div
											style={{
												cursor: "pointer",
												marginTop: "-15px",
												marginBottom: "35px",
												color: "white",
												fontSize: 16,
											}}
											onClick={() => {
												setStep(0)
												setLoad(true)
											}}
										>
											{"<"} Назад
										</div> */}

										<h3
											style={{
												color: "white",
												marginTop: 0,
												marginBottom: 0,
												fontSize: "110%",
											}}
										>
											Введите свои данные самозанятого или ИП
										</h3>
										<div style={{ color: "white", marginBottom: 15 }}>
											С 15го января 2025 мы работаем только с самозанятыми блогерами либо ИП
										</div>

										<label>
											<input
												type="text"
												placeholder="ФИО"
												required={formLegal.LagalConsultation ? false : true}
												maxLength={255}
												name="LegalFIO"
												autoComplete="name"
												value={formLegal.LegalFIO}
												onInput={changeHandlerLegal}
											/>
										</label>
										<label>
											<input
												type="text"
												placeholder="ИНН"
												maxLength={255}
												name="LegalINN"
												value={formLegal.LegalINN}
												onInput={changeHandlerLegal}
											/>
										</label>
										<label>
											<input
												type="checkbox"
												checked={formLegal.LagalConsultation}
												onChange={() => {
													setFormLegal({
														...formLegal,
														LagalConsultation: !formLegal.LagalConsultation,
													})
												}}
											/>{" "}
											У меня нет самозанятости или ИП, но я хочу их оформить
										</label>

										<div style={{ marginTop: 20 }}>
											<label>
												<button onClick={() => {}}>Зарегистрироваться</button>
												<p className="form__new-account reg-text">
													*Отправляя данные, вы соглашаетесь с{" "}
													<Link to={"/privacy/"} target="_blank">
														политикой конфиденциальности
													</Link>
												</p>
											</label>
										</div>
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						{popupError ? (
							<div
								className="popup-msg__link"
								onClick={() => {
									setLoad(true)
									setPopupType("")
								}}
							>
								Продолжить
							</div>
						) : (
							<Link to="/login/" className="popup-msg__link">
								Войти
							</Link>
						)}
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
